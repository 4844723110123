import Cookies from 'js-cookie';
import onLoadAsync from '../shared/on_load_async.js';
import displayLoader from './loader.js';

function hideLoader() {
  document.querySelector('.spinner').classList.remove('active');
}

function verifyCookiePresence(form) {
  if (Cookies.get('download')) {
    Cookies.remove('download');
    form.dispatchEvent(new CustomEvent('DownloadStarted'));
  }
}

onLoadAsync(() => {
  const forms = document.querySelectorAll('.js-loader-file-download');
  forms.forEach((form) => {
    form.addEventListener('submit', () => {
      displayLoader();
      const interval = setInterval(() => verifyCookiePresence(form), 500);
      form.addEventListener('DownloadStarted', () => {
        clearInterval(interval);
        hideLoader();
      });
    });
  });
});
