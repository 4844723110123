import displayLoader from './loader.js';
import onLoadAsync from '../shared/on_load_async.js';

onLoadAsync(() => {
  const dateInput = document.querySelector('.js-daily-chart-date-picker');
  if (!dateInput) {
    return;
  }

  dateInput.addEventListener('change', () => {
    if (!dateInput.value) {
      return; // Don't do anything when field is cleared
    }

    // this will match the part of the URL that goes like "2022/02/14"
    const dateParamsRegexp = /\d{4}\/\d{2}\/\d{2}/;

    // input value is "xxxx-xx-xx", convert it to "xxxx/xx/xx"
    const targetDate = dateInput.value.split('-').join('/');

    window.location.href = window.location.href.replace(dateParamsRegexp, targetDate);
    displayLoader();
  });
});
